import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const backgroundMotion = {
  rest: {
    scale: 1
  },
  hover: {
    scale: 1.05,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 10,
      mass: 1,
      velocity: 0
    }
  }
}

const Button = ({ type, to, text, click }) => {
  switch (type) {
    case 'link':
      return (
        <ButtonMainLink to={to}>
          <motion.div initial="rest" whileHover="hover" animate="rest">
            <ButtonText>{text}</ButtonText>
            <ButtonBackground variants={backgroundMotion} />
          </motion.div>
        </ButtonMainLink>
      )
    case 'a':
      return (
        <ButtonMainA href={to} target="_blank">
          <motion.div initial="rest" whileHover="hover" animate="rest">
            <ButtonText>{text}</ButtonText>
            <ButtonBackground variants={backgroundMotion} />
          </motion.div>
        </ButtonMainA>
      )
    case 'button':
      return (
        <ButtonMainButton>
          <motion.div initial="rest" whileHover="hover" animate="rest" onClick={click}>
            <ButtonText>{text}</ButtonText>
            <ButtonBackground variants={backgroundMotion} />
          </motion.div>
        </ButtonMainButton>
      )
    default:
      return null
  }
}

const ButtonText = styled.span`
  position: relative;
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: 0.1rem;
  z-index: 5;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const ButtonBackground = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colours.primary};
  border-radius: 50px;
  z-index: 1;
`

const buttonStyles = css`
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 1.5rem 3.5rem;
  color: ${props => props.theme.colours.black};
  border: 0;
  background: transparent;
  cursor: pointer;
  border: 0;

  @media (max-width: 1000px) {
    padding: 2rem 3.5rem;
  }

  &:focus {
    outline: 0;
  }
`

const ButtonMainLink = styled(Link)`
  ${buttonStyles}
`

const ButtonMainA = styled.a`
  ${buttonStyles}
`

const ButtonMainButton = styled.button`
  ${buttonStyles}
`

Button.defaultProps = {
  type: 'link',
  to: '/',
  text: 'find out more',
  click: null
}

export default Button

import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { fadeIn, fadeInUp } from './animation/animations'

import Form from './contact/form'
import CloseSvg from '../images/close.svg'

const ContactModal = ({ title, handleContactModalOpen, isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <Outer>
        <Close onClick={handleContactModalOpen} whileHover={{ rotate: 90, scale: 1.2 }} exit="exit">
          <CloseIcon />
        </Close>
        <Inner
          key="inner"
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
          exit="exit"
          variants={fadeInUp}
        >
          <Form title={title} />
        </Inner>
        <BackgroundImage />
        <BackgroundColour
          key="background"
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
          exit="exit"
          variants={fadeIn}
        />
      </Outer>
    )}
  </AnimatePresence>
)

const Outer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`

const Inner = styled(motion.div)`
  position: relative;
  z-index: 5;
`

const BackgroundColour = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colours.black};
  z-index: 1;
`

const BackgroundImage = styled.div`
  position: absolute;
  top: 50%;
  left: -100%;
  width: 200%;
  height: 70%;
  background: linear-gradient(0deg, #00ffe2 0%, #8b01b4 100%);
  filter: blur(50px);
  opacity: 0.12;
  transform: perspective(744px) rotateY(-13deg) rotateZ(-1deg) translateY(-50%);
  z-index: 2;
`

const Close = styled(motion.button)`
  && {
    position: absolute;
    width: 2.1rem;
    height: 2.1rem;
    top: 4rem;
    right: 4rem;
    border: 0;
    background: transparent;
    padding: 0;
    will-change: transform;
    z-index: 10;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`

const CloseIcon = styled(CloseSvg)`
  width: 100%;
  height: 100%;

  line {
    stroke: ${props => props.theme.colours.primary} !important;
  }
`

export default ContactModal
